@import '~Styles/mixins.module';

.button_container {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: rgba(white, 0.3);

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  @include on_click_button;

  .arrow {
    width: 13px;
    height: 13px;
  }

  .right_arrow {
    transform: scale(-1);
  }
}

.disabled {
  opacity: 0.3;
  cursor: not-allowed;

  &:hover {
    transform: unset;
    opacity: 0.3;
  }
}